<template>
  <el-dialog
      title="二维码"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose"
      width="420px">
    <div id="qrcode" ref="qrcode"></div>
  </el-dialog>
</template>

<script>
  import QRCode from "qrcodejs2";

  export default {
  name: "CodeChair",
  props: {
    dialogVisible: {
      type: Boolean,
      default() {
        return false
      }
    },
    currentData: {
      type: Object,
      default() {
        return null;
      }
    },
  },
  watch:{
    dialogVisible(val){
      /*if(val){
        this.selectCode();
      }*/
    }
  },
  data(){
    return{}
  },
  methods: {
    handleClose() {
      this.closeModal()
    },
    closeModal(isRe){
      this.$emit("close", false,isRe);
    },
    selectCode(){
      document.getElementById('qrcode').innerHTML = "";
      //https://yzc-vapp-v1.ycbox.com.cn?device_id=1K55256GGS36U1-5Q&main_way_id=1&auxiliary_way_id=GS&type=0
      /*this.qrcode = new QRCode("qrcode", {
        width: 200, // 二维码宽度
        height: 200, // 二维码高度
        text: "https://yzc-vapp-v1.ycbox.com.cn?device_id=1K55256GGS36U1-5Q&main_way_id=1&auxiliary_way_id=GS&type=0"
      });*/
      this.$axios.post("/sys-api/device-api/getCodeChair",{type:0,deviceId:this.currentData.id},(res) => {
        if(res.code === '100'){
          this.qrcode = new QRCode("qrcode", {
            width: 200, // 二维码宽度
            height: 200, // 二维码高度
            text: res.data
          });
        }
      })
    }
  },
  created() {
    this.$nextTick(() => {
      this.selectCode();
    })
  }
}
</script>

<style scoped lang="scss">
.upload_file {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
}
</style>
